import {
  mdiAccount,
  mdiAlert,
  mdiArchiveEyeOutline,
  mdiArrowDown,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiBellOutline,
  mdiBluetooth,
  mdiCalendarRange,
  mdiCancel,
  mdiCarElectric,
  mdiChartBarStacked,
  mdiCheck,
  mdiCheckboxBlank,
  mdiChevronRight,
  mdiCircle,
  mdiClose,
  mdiCog,
  mdiCommentEdit,
  mdiConsoleLine,
  mdiContentCopy,
  mdiContentDuplicate,
  mdiContentSave,
  mdiCookie,
  mdiCube,
  mdiDelete,
  mdiDevices,
  mdiDockWindow,
  mdiDotsGrid,
  mdiDownload,
  mdiDownloadBox,
  mdiEmailOutline,
  mdiExclamation,
  mdiEye,
  mdiEyeOff,
  mdiFile,
  mdiFileExport,
  mdiFileHidden,
  mdiFileImport,
  mdiFolderDownload,
  mdiFormatListText,
  mdiGestureTapButton,
  mdiHammer,
  mdiHistory,
  mdiInformationOutline,
  mdiLoading,
  mdiLock,
  mdiLockOpenVariant,
  mdiLogin,
  mdiLogoutVariant,
  mdiMagnify,
  mdiMenu,
  mdiMenuDown,
  mdiMenuRight,
  mdiMenuSwap,
  mdiMenuUp,
  mdiMicrophone,
  mdiMinus,
  mdiMusic,
  mdiNavigationVariantOutline,
  mdiOpenInNew,
  mdiPencil,
  mdiPhone,
  mdiPlay,
  mdiPlus,
  mdiRefresh,
  mdiRefreshCircle,
  mdiSeat,
  mdiServer,
  mdiShareVariantOutline,
  mdiShieldCrown,
  mdiSignalCellular3,
  mdiTextShadow,
  mdiTrayArrowDown,
  mdiTriangleSmallUp,
  mdiUpdate,
  mdiViewCompactOutline,
  mdiVolumeHigh,
  mdiWifi,
  mdiWrenchCog,
  mdiDesktopTower,
  mdiCloud,
  mdiHelpNetwork,
  mdiTestTube,
  mdiDragVertical,
  mdiDragHorizontal,
  mdiStop,
  mdiPlusCircle,
  mdiFullscreen,
  mdiHome,
  mdiFileDocumentAlert,
  mdiBug,
} from '@mdi/js'

const icons = {
  SEARCH: mdiMagnify,
  BUILD: mdiHammer,
  CLOSE: mdiClose,
  CLEAR: mdiClose,
  LINK_TO: mdiOpenInNew,
  LOADING: mdiLoading,
  SORT_UNDEFINED: mdiMenuSwap,
  SORT_ASC: mdiMenuUp,
  SORT_DESC: mdiMenuDown,
  EXPANSION: mdiTriangleSmallUp,
  COMPACT: mdiViewCompactOutline,
  FULLSCREEN: mdiFullscreen,
  HOME: mdiHome,

  ARROW_DOWN: mdiArrowDown,
  ARROW_UP: mdiArrowUp,
  ARROW_LEFT: mdiArrowLeft,
  ARROW_RIGHT: mdiArrowRight,

  SUCCESS: mdiCheck,
  INFO: mdiInformationOutline,
  WARNING: mdiExclamation,
  ERROR: mdiAlert,
  MORE: mdiArchiveEyeOutline,

  FILE: mdiFile,
  FILE_UNKNOWN: mdiFileHidden,
  TRACEBACK: mdiFileDocumentAlert,
  MINUS: mdiMinus,
  PLUS: mdiPlus,
  CMD: mdiConsoleLine,
  PLUS_CIRCLE: mdiPlusCircle,

  COPY: mdiContentCopy,
  DUPLICATE: mdiContentDuplicate,
  EDIT: mdiPencil,
  SAVE: mdiContentSave,
  DOWNLOAD: mdiDownload,
  DOWNLOAD_BOX: mdiDownloadBox,
  DOWNLOAD_FOLDER: mdiFolderDownload,
  SHARE: mdiShareVariantOutline,
  DELETE: mdiDelete,
  FOLLOW: mdiChevronRight,
  REFRESH: mdiRefresh,
  UPDATE: mdiUpdate,
  RETRY: mdiRefreshCircle,
  ABORT: mdiCancel,
  STOP: mdiStop,

  EMAIL: mdiEmailOutline,
  ACCOUNT: mdiAccount,
  PASSWORD: mdiLock,
  COMMENT: mdiCommentEdit,
  CONTACT: mdiPhone,

  SETTINGS: mdiCog,
  LOCK: mdiLock,
  LOCK_OPEN: mdiLockOpenVariant,
  SHOWN: mdiEye,
  HIDDEN: mdiEyeOff,
  ACCEPT_COOKIES: mdiCookie,
  HISTORY: mdiHistory,

  CHECKED: mdiCheck,
  UNCHECKED: mdiCheckboxBlank,
  RADIO: mdiCircle,
  CIRCLE: mdiCircle,
  SELECTOR_MENU: mdiMenuRight,
  EXPAND_MENU: mdiMenuDown,
  EXPAND_LEFT: mdiArrowExpandLeft,
  EXPAND_RIGHT: mdiArrowExpandRight,
  MENU: mdiMenu,

  PROJECT: mdiCube,
  NODES: mdiServer,
  TASKS: mdiFormatListText,
  HARDWARE_SETUPS: mdiDevices,
  TAP: mdiTextShadow,
  SIGN_OUT: mdiLogoutVariant,

  AUDIO: mdiMusic,
  NAVIGATION: mdiNavigationVariantOutline,
  VOICE_CONTROL: mdiMicrophone,
  SW_DOWNLOAD: mdiTrayArrowDown,
  DIAGNOSTICS: mdiWrenchCog,
  SIGN_IN: mdiLogin,
  RUN: mdiPlay,
  BOOK_HWS: mdiCalendarRange,
  ADMIN_PANEL: mdiShieldCrown,
  FILE_IMPORT: mdiFileImport,
  FILE_EXPORT: mdiFileExport,

  CELLULAR: mdiSignalCellular3,
  WIFI: mdiWifi,
  BLUETOOTH: mdiBluetooth,
  VOLUME: mdiVolumeHigh,
  SEAT: mdiSeat,
  DOTS: mdiDotsGrid,
  HMI: mdiGestureTapButton,
  PLATFORM: mdiCarElectric,
  MULTITASK: mdiDockWindow,
  TD_REAL_HW: mdiDesktopTower,
  TD_VIRTUAL_HW: mdiCloud,
  TD_UNKNOWN: mdiHelpNetwork,

  REPORT: mdiChartBarStacked,
  NOTIFICATIONS: mdiBellOutline,
  TEST: mdiTestTube,
  BUG: mdiBug,

  VERTICAL: mdiDragVertical,
  HORIZONTAL: mdiDragHorizontal,
}

export default icons
